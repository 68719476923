import * as React from "react"
import { Grid } from '@mui/material'

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invert : false
    }
  }
  render() {    
    return (
      <div className="container">      
        {this.props.children}
      </div>
    )
  }
}

export default Layout
