import * as React from "react"
import { FaDownload, FaCheck} from "react-icons/fa"
import { Box, Grid, Tabs, Tab, Select, MenuItem, Alert, AlertTitle } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';
import Video from "./video-container";
import logo from "../../assets/logo.png"

import "../../assets/styles.css"

const API_ENDPOINT = "https://xe95cuw517.execute-api.ap-southeast-1.amazonaws.com/dev"

class ContentCreation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invert : false,
      user: "",
      value: "text_to_video",
      tabIndex: 0,
      loading: true,
      retrieve_prompt:true,
      textovideo_server:false,
      textovideo_starting:false,
      startcontainerbutton: true,
      textovideo_processing:false,
      prompt: "",
      model:1,
      alert: false,
      alert_message: "",

      dashboardurls: {
        treasury_report: "",
        onchain_activity_report: "",
        armory_report: "",
        scholar_performance_report: "",
        audience_report: "",
        base_gfc_dashbord: ""      
      },
      requestdetails: []
    }
    this.submitTTVRequest = this.submitTTVRequest.bind(this);    
    this.containerStopStart = this.containerStopStart.bind(this);
    this.handlePromptChange = this.handlePromptChange.bind(this);
    this.handleModelChange = this.handleModelChange.bind(this);
    this.handleAlertMessage = this.handleAlertMessage.bind(this);
    this.reload_requestdetails = this.reload_requestdetails.bind(this);

  }
  async componentDidMount() {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');    
    headers.append('Access-Control-Allow-Origin','*');
    headers.append('Origin','*');
    
    while(true) {
      this.reload_requestdetails()      
      await new Promise(r => setTimeout(r, 5000));
    }
  }
  reload_requestdetails = () => {
    fetch( API_ENDPOINT +'/checkttvstatus/', { 
      method: 'get'
    }).then(response => response.json()).then((data) => {
      if(this.state.textovideo_starting === true){
        if(data.body===true){
          this.setState({
            textovideo_server: true,
            textovideo_starting: false,
          })
        }
      }
      else{

        if(data.body===true){
          this.setState({
            textovideo_server: true,
            textovideo_starting: false,
          })
        }
        else{
          this.setState({
            textovideo_server: false,
            textovideo_starting: false,
          })
        }
      }      
    }).catch((error) => {console.log(error)})

    fetch( API_ENDPOINT +'/retrieverequestdetails/', { 
      method: 'get'
    }).then(response => response.json()).then((data) => {
      // console.log(data.body)
      let request_processing = false;
      for(let i=0; i<data.body.length; i++){
        if(data.body[i].completed === false){          
          request_processing = true;
        }
      }
      if(request_processing === false){
        this.setState({
          user: this.props.user,
          requestdetails: data.body,
          loading: false,
          textovideo_processing: false
        })
      } else {
        this.setState({
          user: this.props.user,
          requestdetails: data.body,
          loading: false,
          textovideo_processing: true
        })
      }
    }).catch((error) => {console.log(error)})
  }
  handlePromptChange = (event) => {
    event.preventDefault()
    this.setState({
      prompt: event.target.value
    })
    // console.log(event.target.value)    
  }
  handleModelChange = (event) => {
    event.preventDefault()
    this.setState({
      model: event.target.value
    })
    // console.log(event.target.value)    
  }
  handleAlertMessage(message) {
    event.preventDefault()
    if(this.state.alert === false){
      this.setState({
        alert_message: message,
        alert: true
      })
    } else {
      this.setState({
        alert_message: "",
        alert: false
      })
    }
    // console.log(message)    
  }
  componentWillUnmount() {

  }  
  containerStopStart = (event) => {

    let lambdaevent = {
      instance_id:"i-0be14f93e1bfd8ca7"
    }
    
    if(this.state.textovideo_starting === false){
      
      this.setState({
        textovideo_starting: true
      })
      lambdaevent["action"] = "start"
    } else {
      this.setState({
        textovideo_starting: false
      })
      lambdaevent["action"] = "stop"
    }
    
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');    
    headers.append('Access-Control-Allow-Origin','*');
    headers.append('Origin','*');
    var requestConfig = {
      method: 'POST',
      body: JSON.stringify(lambdaevent),}      
      // headers: headers}

    fetch( API_ENDPOINT +'/controlttvcontainer/', requestConfig)
    .then(response => response.json()).then((data) => {
      // console.log(data)
      
    }).catch((error) => {console.log(error)})
     
    
  }
  submitTTVRequest = (event) => {
    event.preventDefault()
    if(this.state.textovideo_server === true){
      if(this.state.prompt != ""){
        if(this.state.textovideo_processing === false){
          fetch(API_ENDPOINT +'/submitvideorequest/', { 
            method: 'post',
            body: JSON.stringify({
              prompt: this.state.prompt,
              model: this.state.model
            })
            }).then(response => response.json()).then((data) => {
            // console.log(data)
            if(data.body===true){
              this.setState({
                retrieve_prompt: true
              })
            }      
            }).catch((error) => {console.log(error)})
            this.reload_requestdetails()
            this.setState({
              prompt: "", 
              textovideo_processing: true})
        } else {
          this.handleAlertMessage("Please wait for the current request to finish processing")}
      } else {
        this.handleAlertMessage("Please enter a prompt")}
    } else {
      this.handleAlertMessage("Please start the container first")}
  }
  
    
  render() {    
    return (      
      <div className="tabs_container">
        <div className="tab_header">
          <Grid container spacing={0} >
            <Grid item xs={4} >
              <div className="logo_container">
                <img className="logo" src={logo} alt="logo" />
              </div>
            </Grid>
            <Grid item xs={8} >
              <Grid container spacing={0} >
                <Grid item xs={8} />
                <Grid item xs={4} >
                  <div className="signout_button">
                    <button onClick={this.props.signOut}>Sign out {this.props.user.attributes.email}</button>
                  </div>
                </Grid>
                <Grid item xs={12} >
                  <Box sx={{ borderBottom: 2, borderColor: 'divider' }}>
                    <Tabs
                      value={this.state.value}
                      variant="fullWidth"
                      onChange={this.handleTabChange}
                      textColor="secondary"
                      indicatorColor="secondary">
                      <Tab value="text_to_video" id="text_to_video" label="Text to Video" />
                    </Tabs>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className="tab_detail">
          {this.state.loading ? 
            <div className="loading_spinner">
              <CircularProgress sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: `auto`,
                marginLeft: `auto`
              }}/>
            </div>
            :
            <Grid container spacing={0} >
              <Grid item xs={3} />
              <Grid item xs={6} >
                <div className="controlcentre">
                  <Grid container spacing={0} >
                    <Grid item xs={12} >
                      <Grid container spacing={0} >
                        <Grid item xs={1} />
                        <Grid item xs={4} >
                          
                          {this.state.textovideo_server ?
                            <div className="container_action_button" onClick={this.containerStopStart}>Stop Container</div>
                            :
                            <div>
                              {this.state.textovideo_starting ?
                                <div className="container_action_button_alt" >Container Starting.....</div>
                              :
                                <div className="container_action_button" onClick={this.containerStopStart}>Start Container</div>
                              }
                            </div>
                            
                          }
                          
                        </Grid>
                        <Grid item xs={1} />
                        <Grid item xs={2} >
                          {this.state.textovideo_starting ?
                            <div className="prompt_retrieval_spinner">
                              <CircularProgress size={20}/>
                            </div>
                            :
                            <div />
                          }

                        </Grid>
                        <Grid item xs={4} >
                          {this.state.textovideo_server ?
                            <p>Text to Video Container <block style={{color:"#009600"}}>RUNNING</block></p>
                            :
                            <p>Text to Video Container <block style={{color:"#FF0000"}}>STOPPED</block></p>
                          }
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}  >
                    <Grid item xs={12} >
                      <div className="prompt_container">
                        <form id="submit-prompt"
                          method="post"
                          onSubmit={this.submitTTVRequest}>
                          <Grid container spacing={0} >
                            <Grid item xs={1} />
                            <Grid item xs={11} >
                              <h3>Enter a prompt for the video clip you would like</h3>                            
                            </Grid>
                            <Grid item xs={1} />
                            <Grid item xs={8} >
                              <input className="prompt_input" onChange={this.handlePromptChange} type="text" 
                                id="prompt_input" name="prompt" placeholder="Enter your prompt..." value={this.state.prompt} />
                            </Grid>
                            <Grid item xs={2} >
                              <Select className="modelselect_input" labelId="model-select-label" id="model-select"
                                value={this.state.model} label="Model" onChange={this.handleModelChange}>
                                <MenuItem value={1}>Model 1</MenuItem>
                                <MenuItem value={2}>Model 2</MenuItem>                                
                              </Select>
                            </Grid>
                            <Grid item xs={1} />
                            <Grid item xs={1} />
                            <Grid item xs={6} >
                              <input className="prompt_submit" type="submit" id="prompt_submit" name="prompt_submit" />                              
                            </Grid>
                            <Grid item xs={5} >
                              
                            </Grid>
                            <Grid item xs={12} >                              
                              {this.state.alert ? (
                                <div className="alert_box">
                                  <Alert severity="error" onClose={() => {this.setState(
                                    {alert_message: "", alert:false})}}>
                                    <AlertTitle>Error</AlertTitle>
                                    {this.state.alert_message}
                                  </Alert>
                                </div>
                              ) : (<div/>)
                              }
                              
                            </Grid>
                          </Grid>
                        </form>
                      </div>
                    </Grid>
                    <Grid item xs={12} >
                      <div className="prompthistory_container">
                        <Grid container spacing={0} >
                          <Grid item xs={1} />
                          <Grid item xs={11} >
                            <h3>Prompt History</h3>
                          </Grid>
                          <Grid item xs={12} >
                            <div className="prompthistory">
                              <ul className="prompt_history_items">
                                {this.state.requestdetails.map((prompt, index) => (
                                  <li key={index} className="prompt_history_li_item">{
                                    <div>
                                      <div className="prompt_history_item">
                                        <Grid container spacing={0} >
                                          <Grid item xs={1} />
                                          <Grid item xs={9} >
                                            <p className="prompt_history_item_text">{prompt.prompt}</p>
                                          </Grid>
                                          <Grid item xs={1}>
                                            {prompt.completed === false ?
                                              <div className="prompt_retrieval_spinner">
                                                <CircularProgress size={20}/>
                                              </div>
                                              :
                                              <div className="video_items">
                                              <Grid container spacing={0} >
                                                <Grid item xs={1} />
                                                <Grid item xs={3} >
                                                  <FaCheck className="video_icon" size={20}/>                                                  
                                                </Grid>
                                                <Grid item xs={4} />
                                                <Grid item xs={3} >
                                                  <a href={prompt.filekey} className="video_download" target="_blank" rel="noreferrer">
                                                    <FaDownload className="video_icon" size={20}/>
                                                  </a>
                                                </Grid>
                                              </Grid>
                                              </div>
                                            }                                            
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </div>}</li>
                                ))}
                              </ul>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={3} />
              {/* <Grid item xs={8} >
                <Box>                                  
                  <div id="dashboardContainer" className="dashboardContainer">
                    <Grid container spacing={1} >
                      {this.state.requestdetails.map((prompt, index) => (
                        <Grid item xs={4} key={index} >
                          {prompt.completed === false ?
                            <div className="video_container">
                              <CircularProgress size={60}/>
                            </div>
                            :
                            <Video videoSrcURL={prompt.filekey} videoTitle={prompt.prompt} />
                            }
                        </Grid>

                      ))}
                    </Grid>
                  </div>                
                </Box>
              </Grid>                    */}
            </Grid>
          }
        </div>
      </div>
    
    )
  }
}

export default ContentCreation
